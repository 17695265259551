<template>
  <div class="flex w-full cursor-pointer">
    <div class="relative w-full flex">
      <Dialog>
        <DialogTrigger as-child>
          <div
            class="relative flex group w-full md:aspect-video aspect-[9/16] overflow-hidden"
          >
            <video
              src="/videos/artist-retreat.mp4"
              muted
              autoplay
              playsinline
              loop
              class="absolute w-auto min-w-full h-full md:h-auto md:w-full object-cover left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            ></video>
            <div
              class="absolute text-white text-3xl z-10 lg:text-5xl flex flex-col text-left p-4 lg:p-16 [text-shadow:_0_3px_0_rgb(0_0_0)]"
            >
              <span>Discover our</span>
              <span>last artist retreat short-film</span>
            </div>
            <button
              class="absolute md:opacity-0 md:group-hover:opacity-100 transition-opacity duration-300 z-20 text-white left-1/2 md:left-auto bottom-0 -translate-x-1/2 md:translate-x-0 right-0 mb-10 md:m-4 lg:m-16 p-1 lg:py-2 uppercase border-white border text-xl lg:text-xl font-medium w-fit px-3 md:px-4"
            >
              Play video
            </button>
          </div>
        </DialogTrigger>
        <DialogContent class="w-[95%] md:w-4/5 lg:max-w-none pt-12">
          <iframe
            class="w-full aspect-video"
            src="https://www.youtube.com/embed/7gvhY0HuP_w?si=SxbksYKq4cXIEUt1?&autoplay=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            aspectRatio="16/9"
          ></iframe>
        </DialogContent>
      </Dialog>
    </div>
  </div>
</template>
