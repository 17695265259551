import { useLocalStorage } from "@vueuse/core";

const timeout = ref<number>()


export default function useAutoNewsletter({callback}:{callback?: () => void}){

    
    onMounted(() => {
        let firstVisit = window.localStorage.getItem('firstVisit')

        if(firstVisit){return}
    
        if(timeout.value) {return}
        
        timeout.value = window.setTimeout(() => {
            firstVisit = window.localStorage.getItem('firstVisit')

            if(firstVisit){return}

            if(callback){
                callback()
            }
            localStorage.setItem('firstVisit', Date.now().toString())
        }, 15_000)

    })

    function onSubmit(){
        localStorage.setItem('newsletterSubmit', Date.now().toString())
    }

    return {onSubmit}
}